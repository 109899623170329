import { api } from '.';
import type { User } from '@backend/entities/User';

export const usersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUser: build.query<API.Response<User>, void>({
            query: () => ({
                url: '/users',
            }),
        }),
        getAdmins: build.query<API.Response<number[]>, void>({
            query: () => ({
                url: '/users/admins',
            }),
        }),
    }),
});

export const { useGetUserQuery, useGetAdminsQuery } = usersApi;
