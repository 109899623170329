import { useGetAdminsQuery, useGetUserQuery } from '@/store/api/user';
import { Text } from '@telegram-apps/telegram-ui';
import { PageSpinner } from '../PageSpinner';
import { FullPage } from '../FullPage';
import { Roles } from '@backend/types/enums';

export function Auth({ children }: React.PropsWithChildren) {
    const { isLoading: isLoadingUser, isSuccess, isError, data: user } = useGetUserQuery();
    const { isLoading: isLoadingAdmins } = useGetAdminsQuery(undefined, { skip: user === undefined || user.data.role !== Roles.Admin });

    const isLoading = isLoadingUser || isLoadingAdmins;

    if (isLoading) {
        return <PageSpinner />;
    }

    if (isError) {
        return (
            <FullPage>
                <Text>Произошла ошибка при загрузке данных профиля</Text>
            </FullPage>
        );
    }

    if (isSuccess) {
        return <>{children}</>;
    }
}
