export enum Roles {
    Admin,
    User,
}

export enum ConsumableType {
    Cookie,
    Proxy,
}

export enum ConsumableStatus {
    Active,
    Inactive,
}

export enum ParsingType {
    Manual,
    Scheduled,
}
